/*
    Use the DejaVu Sans font for displaying and embedding in the PDF file.
    The standard PDF fonts do not support Unicode characters.
*/
.print-font-family div {
  font-family: "DejaVu Sans", "Arial", sans-serif;
}

/*
  The example loads the DejaVu Sans from the Kendo UI CDN.
  Other fonts have to be hosted from your application.
  The official site of the Deja Vu Fonts project is
  https://dejavu-fonts.github.io/.
*/
@font-face {
  font-family: "DejaVu Sans";
  src: url("../../../public/assets/fonts/DejaVuSans.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src: url("../../../public/assets/fonts/DejaVuSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  src: url("../../../public/assets/fonts/DejaVuSans-Oblique.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-style: italic;
  src: url("../../../public/assets/fonts/DejaVuSans-Oblique.ttf") format("truetype");
}